<template>
  <div class="sales-parameter">
    <PageTitle
      title="銷售紀錄參數設定"
      icon="chevron_left"
      hideBtn
      style="padding-bottom: 24px"
      @iconClick="$router.push({ name: 'SalesParameterSetting' })"
    />
    <section class="section-block">
      <SectionTitle title="銷售紀錄作廢設定" @edit="passwordDialog = true, formData.password = config.invalidPassword" />
      <el-form :model="config" label-position="left" label-width="200px">
        <el-form-item label="密碼">
          <div class="secret-text collapse">
            <span>{{ config.useInvalidPassword ? '已設置' : '尚未設定' }}</span>
          </div>
        </el-form-item>
      </el-form>
    </section>

    <section class="section-block">
      <SectionTitle title="建立銷售紀錄設定" @edit="paymentTypesDialog = true" />

      <el-form label-position="left" label-width="200px">
        <el-form-item label="付款方式" style="margin-bottom: 0px">
          <div v-if="config.paymentTypes" class="secret-text">
            <span class="payments-list">{{ payments }}</span>
            <span v-if="config.paymentTypes.length === 0">尚未設定</span>
          </div>
        </el-form-item>
        <el-form-item label="紀錄身分模式">
          <div class="secret-text">
            <span>{{ config.identityMode ? '非會員模式' : '會員模式' }}</span>
          </div>
        </el-form-item>
      </el-form>
    </section>

    <MemberPointConfigBlock
      v-if="checkAction('admin.shopPoint.page')"
      v-loading="loading"
      title="銷售紀錄點數設定"
      :configData="memberPointConfig"
      source="SALE_RECORD"
      rewardType="POINT"
      @refresh="refresh"
    />

    <!-- 設定密碼 Dialog -->
    <el-dialog
      v-if="passwordDialog"
      :close-on-click-modal="false"
      :visible="true"
      title="編輯銷售紀錄作廢設定"
      @close="passwordDialog = false"
    >
      <el-form ref="form" label-position="top">
        <el-form-item label="密碼">
          <el-input
            v-model="formData.password"
            class="dialog-input"
            placeholder="請輸入"
            show-password
          />
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button plain @click="passwordDialog = false">取消</el-button>
        <el-button :disabled="!formData.password" :loading="loading" type="primary" @click="setSalesPassword">儲存</el-button>
      </div>
    </el-dialog>

    <!-- 付款方式 Dialog -->
    <el-dialog
      v-if="paymentTypesDialog"
      :close-on-click-modal="false"
      :visible="true"
      title="編輯建立銷售紀錄設定"
      @close="formData.identityMode = config.identityMode"
    >
      <el-form ref="form" label-position="top">
        <el-form-item label="付款方式">
          <SalesPaymentSelect :model.sync="formData.paymentTypes" multiple />
        </el-form-item>

        <el-form-item label="紀錄身分模式" style="margin-bottom: 5px">
          <el-switch
            v-model="formData.identityMode"
            class="switch-input"
            active-text="非會員模式"
            inactive-text="會員模式"
          />
        </el-form-item>
        <div class="flex-col">
          <span class="note">會員模式：以紀錄電話方式在銷售報表</span>
          <span class="note">非會員模式：以紀錄來客身份類別在銷售報表</span>
        </div>
      </el-form>

      <div slot="footer">
        <el-button plain @click="paymentTypesDialog = false">取消</el-button>
        <el-button
          :loading="loading"
          type="primary"
          @click="updateSalesRecordConfig"
        >
          儲存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageTitle from '@/components/Title/PageTitle.vue'
import SectionTitle from '@/components/Title/SectionTitle'
import {
  GetSalesConfig,
  UpdateShowMember,
  SetSalesPassword,
  UpdateSalesPaymentTypes,
  // GetSalesPaymentTypes
} from '@/api/sales'
import { checkUserFeature } from '@/store/modules/permission'
import { mapGetters } from 'vuex'
import SalesPaymentSelect from '@/components/Select/SalesPaymentSelect.vue'
import MemberPointConfigBlock from '@/views/Parameters/components/MemberPointConfigBlock.vue'
import { usePermissions } from '@/use/permissions'

export default {
  name: 'SalesModuleSettings',
  components: { SalesPaymentSelect, PageTitle, SectionTitle, MemberPointConfigBlock },
  setup (props) {
    const { checkAction } = usePermissions()
    return { checkAction }
  },
  data: () => ({
    memberPointConfig: {},
    config: {},
    passwordDialog: false,
    paymentTypesDialog: false,
    loading: false,

    formData: {
      password: '',
      paymentTypes: [],
      identityMode: false,
      showMemberDetail: false,
    },
  }),
  computed: {
    ...mapGetters(['userPlanFeature', 'userFeatures']),
    shopId () {
      return this.$store.getters.shop
    },
    payments () {
      if (!this.config.paymentTypes) return '尚未設置'
      let types = this.config.paymentTypes
      if (!this.useWallet) types = types.filter(p => p.type !== 'wallet')
      const list = []
      for (const type of types) {
        list.push(type.name)
      }
      return list.join('、')
    },
    useWallet () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.salesRecord.create.walletPayment')
    },
  },

  async mounted () {
    await this.getSalseConfig()
  },
  methods: {
    async updateSalesRecordConfig () {
      this.loading = true
      await this.updateSalesPaymentTypes()
      await this.updateConfig('identityMode')
      this.paymentTypesDialog = false
      this.loading = false
    },
    async getSalseConfig () {
      try {
        const res = await GetSalesConfig({ shopId: this.shopId })
        this.config = res
        this.formData.paymentTypes = res.paymentTypes.filter(
          (item) => item.type !== 'cash' && item.type !== 'wallet',
        )
        this.formData.identityMode = this.config.identityMode
        this.formData.showMemberDetail = this.config.showMemberDetail
        this.formData.password = this.config.invalidPassword
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
      }
    },

    async setSalesPassword () {
      this.loading = true
      try {
        await SetSalesPassword({
          shopId: this.shopId,
          password: this.formData.password,
        })
        await this.getSalseConfig()
        this.$message.success('更新成功 !')
        this.passwordDialog = false
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
        this.$message.error(error || error.message)
      }
    },

    async updateSalesPaymentTypes () {
      try {
        this.formData.paymentTypes.push(
          { name: '現金', type: 'cash', order: 1 },
        )

        // 如果有開儲值金功能，再增加儲值金支付
        if (this.useWallet) {
          this.formData.paymentTypes.push(
            { name: '儲值金', type: 'wallet', order: 2 },
          )
        }

        await UpdateSalesPaymentTypes({
          shopId: this.shopId,
          paymentTypes: this.formData.paymentTypes,
        })
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
      }
    },

    async updateConfig (mode) {
      try {
        console.log(this.formData.showMemberDetail)
        await UpdateShowMember({
          shopId: this.shopId,
          showMember:
            mode === 'showMember' ? this.config.showMember : undefined,
          identityMode:
            mode === 'identityMode' ? this.formData.identityMode : undefined,
          showMemberDetail:
            mode === 'showMemberDetail'
              ? this.config.showMemberDetail
              : undefined,
        })
        this.$message.success('更新成功 !')
        await this.getSalseConfig()
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
      }
    },
  },
}
</script>

<style lang="scss">
.el-switch__label span {
  font-size: 16px;
}
</style>

<style scoped lang="scss">
section {
  margin-top: 2rem;
  margin-bottom: 50px;
}

.title {
  font-size: 20px;
  margin-right: 10px;
}

.link-card {
  margin-bottom: 20px;
}

.sub-title {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 500;

  .title-hint {
    @apply text-primary-100 mr-[5px];
  }
}

.secret-text,
.note {
  // width: 100px;
  font-weight: 400;
  @apply text-gray-80;
  letter-spacing: 1px;
}

.collapse {
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.payments-list {
  width: 400px;
  word-wrap: break-word;
}
</style>
